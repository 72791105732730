//import * as React from "react";

import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
//import routes from './routes';
import GenRoutes from './routes';
import '../fake-db';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import React, { useState, useEffect, useContext } from "react"
import axios from 'axios'
import { hubsupportalApi, apiUrl, refreshToken } from 'app/utils/utils';
import { useCookieState } from "use-cookie-state";

import { SnackbarProvider, useSnackbar } from 'notistack';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import { wsUrl, formatTime2 } from 'app/utils/utils';


const queryClient = new QueryClient()
export const HubIdContext = React.createContext({
  hubId: "",
  setHubId: () => { },
  hubList: {},
  setHubList: () => { },
  currentHubSelectionMethod: "",
  setCurrentHubSelectionMethod: () => { },
  sendMessage: () => {},
  lastMessage: "",
  readyState: ReadyState.UNINSTANTIATED
});

const App = () => {

// Custom hook to manage WebSocket connection
const useWebSocketConnection = () => {
  let socketUrl = wsUrl() + "action";
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => console.log('WebSocket opened'),
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000,
    reconnectAttempts: Infinity,
  });

  return { sendMessage, lastMessage, readyState };
};
const { sendMessage, lastMessage, readyState } = useWebSocketConnection();

  React.useEffect(() => {
    function login_check() {
      (async () => {
        try {
          const response = await hubsupportalApi.get("login_check"); //send API call to authenticate
        } catch (e) {
          console.log(e);
        }
      })();
    }
    const timer = setInterval(login_check, 5 * 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    function auto_token_refresh() {
      (async () => {
        try {
          const resp = await refreshToken();
          localStorage.setItem("access_token", resp.access_token);
          localStorage.setItem("refresh_token", resp.refresh_token);
        } catch (e) {
          console.log(e);
        }
      })();
    }
    const timer = setInterval(auto_token_refresh, 30 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  //const content = useRoutes(routes);
  const [actions, setActions] = useState({});
  const [reports, setReports] = useState({});


  const [hubId, setHubId] = useCookieState("hubid", "LM8HA1");
  const [hubList, setHubList] = useCookieState("hubList", {});
  const [currentHubSelectionMethod, setCurrentHubSelectionMethod] = useCookieState("currentHubSelectionMethod", "single");

 
  const hubIdContext = { 
    hubId, setHubId, 
    hubList, setHubList, 
    currentHubSelectionMethod, setCurrentHubSelectionMethod, 
    sendMessage, lastMessage, readyState 
  };
  console.log(hubIdContext);

  if (typeof hubList == "string") {
    try {
      setHubList(JSON.parse(hubList));
    } catch (e) {
      console.log(e);
    }
  }


  useEffect(() => {
    (async () => {
      try {
        const response = await hubsupportalApi.get("hs_action");

        //console.log("AuthNavigations","hs_action", response);
        //console.log("AuthNavigations","hs_action.data", response.data);
        //console.log("AuthNavigations","hs_action.data.data", response.data.data);

        setActions(response.data.data);

      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await hubsupportalApi.get("hs_report");

        console.log("AuthNavigations","hs_report", response);
        console.log("AuthNavigations","hs_report.data", response.data);
        console.log("AuthNavigations","hs_report.data.data", response.data.data);

        setReports(response.data.data);

      } catch (err) {
        console.error(err);
      }
    })();
  }, []);
  const content = useRoutes(GenRoutes(actions,reports));


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const foo = params.get('hubId');
  console.log("queryParm", foo)
  if (foo && (foo != hubId || currentHubSelectionMethod != "single")) {
    setHubId(foo);
    setCurrentHubSelectionMethod("single");
  }

  return (

    <SettingsProvider>
      <AuthProvider>
        <HubIdContext.Provider value={hubIdContext}>
          <MatxTheme>
            <SnackbarProvider maxSnack={3}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <QueryClientProvider client={queryClient} >
                  {content}
                </QueryClientProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </MatxTheme>
        </HubIdContext.Provider>
      </AuthProvider>
    </SettingsProvider>

  );
};

export default App;
