import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import { Token } from '@mui/icons-material';
import { jwtDecode } from 'jwt-decode'
import { apiUrl } from 'app/utils/utils';

console.log("JWTAuthContext", "load");

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
  loginRequired: false
};

console.log("JWTAuthContext", "initialState", initialState);


// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem('accessToken', accessToken);
//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     localStorage.removeItem('accessToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      console.log("JWTAuthContext", "reducer", "INIT", action.payload);
      const { isAuthenticated, loginRequired, user } = action.payload;
      return { ...state, isAuthenticated, loginRequired, isInitialised: true, user };
    }

    case 'LOGIN': {
      console.log("JWTAuthContext", "reducer", "LOGIN", action.payload);
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      console.log("JWTAuthContext", "reducer", "LOGOUT", action.payload);
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      console.log("JWTAuthContext", "reducer", "REGISTER", action.payload);
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      console.log("JWTAuthContext", "reducer", "default", action.payload);
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => { },
  logout: () => { },
  register: () => { }
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    /*
    const response = await axios.post('/api/auth/login', { email, password });
    const { user } = response.data;
    dispatch({ type: 'LOGIN', payload: { user } });
    */

    console.log("JWTAuthContext", "login debug 2 - AuthProvider ");

    console.log("JWTAuthContext", "AuthProvider", "login", email, password);

    dispatch({ type: 'LOGIN', payload: {} });

  };

  const register = async (email, username, password) => {
    const response = await axios.post('/auth/register', { email, username, password });
    const { user } = response.data;

    dispatch({ type: 'REGISTER', payload: { user } });
  };

  const logout = async () => {
    console.log("JWTAuthContext", "AuthProvider", "logout");

    /*
    const currentUrl = window.location.href;
    let url = new URL(currentUrl)
    // 'http://mdm.armware.com:3001/api/auth/profile'
    let logoutUrl = url.protocol + "//" + url.hostname + ":3001/auth/logout";
    */
    const response = await axios.post(apiUrl("/auth/logout"), {});
    const { user } = response.data;

    localStorage.setItem("access_token", "");
    localStorage.setItem("refresh_token", "");

    document.cookie = "tokens=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";


    dispatch({ type: 'LOGOUT' });
  };


  if (!state.isAuthenticated) {

    try {
      console.log("JWTAuthContext", " cookie=", document.cookie);
      let tokens_str = document.cookie.split('; ').filter(row => row.startsWith('tokens=')).map(c => c.split('=')[1])[0];
      console.log("JWTAuthContext", " tokens=", tokens_str);
      if (tokens_str) {
        let tokens = JSON.parse(tokens_str);
        if (tokens) {
          localStorage.setItem("access_token", tokens.access_token);
          localStorage.setItem("refresh_token", tokens.refresh_token);

          const decoded = jwtDecode(tokens.access_token);

          console.log("JWTAuthContext", "jwtDecode=", decoded);

          let myuser = {
            "id": 1,
            "roles": decoded.hs_roles,
            "name": decoded.name,
            "username": decoded.preferred_username,
            "email": decoded.email,
            "avatar": "/assets/images/face-6.jpg",
            "age": 25,
            "jwt_data": decoded
          };
          console.log("JWTAuthContext", "login debug 11 - login using cookie ");
          dispatch({ type: 'INIT', payload: { isAuthenticated: true, loginRequired: false, user: myuser } });
          return;
        }
      }

    } catch (e) {
      console.log("JWTAuthContext", "!state.isAuthenticated - exception - ", e);
    }


    if (!state.isInitialised) {
      console.log("JWTAuthContext", "login debug 12");
      dispatch({ type: 'INIT', payload: { isAuthenticated: false, loginRequired: true, user: null } });
    }

  }

  /*
  useEffect(() => {
    (async () => {
      try {
        console.log("JWTAuthContext","useEffect");
        //const { data } = await axios.get('/api/auth/profile');

        const currentUrl = window.location.href;
        let url = new URL(currentUrl)
        // 'http://mdm.armware.com:3001/api/auth/profile'
        let profileUrl = url.protocol + "//" + url.hostname + ":3001/auth/profile";
        axios.defaults.withCredentials = true; //overkill - just want it to work!!!
        const { data } = await axios.post(profileUrl, {}, { withCredentials: true });
        console.log("JWTAuthContext","profile_data", data);

        let access_token = data.access_token;
        localStorage.setItem("access_token",access_token);
        let refresh_token = data.refresh_token;
        localStorage.setItem("refresh_token",refresh_token);
        
        //axios.defaults.headers.common['Authorization'] = '';
        //delete axios.defaults.headers.common['Authorization'];
        //axios.defaults.headers.common['Authorization'] = "Bearer " + access_token;
        

        const decoded = jwtDecode(access_token);

        console.log(decoded);

        let myuser = {
          "id": 1,
          "roles": decoded.hs_roles,
          "name": decoded.name,
          "username": decoded.preferred_username,
          "email": decoded.email,
          "avatar": "/assets/images/face-6.jpg",
          "age": 25,
          "jwt_data" : decoded
        };
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, loginRequired: false, user: myuser } });

        // dispatch({ type: 'INIT', payload: { isAuthenticated: true, loginRequired: false, user: data.user } });
      } catch (err) {
        console.log(err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, loginRequired: true, user: null } });
        console.error(err);
      }
    })();
  }, []);
  */

  // SHOW LOADER
  if (!state.isInitialised) {
    console.log("JWTAuthContext", "not initialized", "MatxLoading");
    return <MatxLoading />;
  }

  if (state.loginRequired) {
    console.log("JWTAuthContext", "login required");
    console.log("JWTAuthContext", "login debug 13 - login required ");
    dispatch({ type: 'INIT', payload: { isAuthenticated: false, loginRequired: false, user: null } });

    /*
    const currentUrl = window.location.href;
    let url = new URL(currentUrl)
    // 'http://mdm.armware.com:3001/api/auth/login'
    let loginUrl = url.protocol + "//" + url.hostname + ":3001/auth/login";
    */

    let url = apiUrl("/auth/login");
    console.log("JWTAuthContext", "login debug 3 - AuthProvider ", url);
    window.location.replace(url);
    return "";
  }

  console.log("JWTAuthContext", "return AuthContext.Provider");

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
