import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import { allowAction } from 'app/utils/utils';


// dashboard page
const Dashboard = Loadable(lazy(() => import('app/views/dashboard/Dashboard')));
const ActionDashboard = Loadable(lazy(() => import('app/views/dashboard/ActionDashboard')));
const ReportDashboard = Loadable(lazy(() => import('app/views/dashboard/ReportDashboard.tsx')));
//const OldHubLogging = Loadable(lazy(() => import('app/views/dashboard/HubLogging')));
const HubLogging = Loadable(lazy(() => import('app/HubLogging/LoggingTable.tsx')));



// demo - session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,

      // dashboard route
      {
        path: '/dashboard/hub',
        element: <Dashboard />,
        auth: authRoles.admin
      },

      {
        path: '/dashboard/hubs',
        element: <Dashboard />,
        auth: authRoles.super_admin
      },

      {
        path: '/dashboard/logging',
        element: <HubLogging />,
        /* element: <HubLogging hubId="LM8HA1B3YW3"/>, */
        auth: authRoles.admin
      },

      {
        path: '/dashboard/reports',
        element: <Dashboard />,
        auth: authRoles.admin
      },

    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },

  { path: '/', element: <Navigate to="dashboard/hub" /> },
  { path: '*', element: <NotFound /> }
];


export function GenRoutes(actions,reports) {
  try {
    if (actions) {
      console.log(actions);
      console.log(routes);
      console.log(routes[0]);
      console.log(routes[0].children);

      let actionItems = [
        {
          path: '/action/xBLE_SCAN_linux',
          element: <Dashboard command='xBLE_SCAN_linux' />,
          auth: authRoles.admin
        },
        {
          path: '/dashboard/reports',
          element: <Dashboard />,
          auth: authRoles.admin
        },
        {
          path: '/dashboard/reports',
          element: <Dashboard />,
          auth: authRoles.admin
        },
      ];

      console.log(actionItems);
      routes[0].children = routes[0].children.concat(actionItems);

      for (let action_idx1 = 0; action_idx1 < actions.length; action_idx1++) {
        let action = actions[action_idx1];
        //always load the route - if (allowAction(action)) 
        {

          let actionItem = [
            {
              path: '/action/'+action.action_name,
              element: <ActionDashboard action={action} />,
              auth: authRoles.admin
            },
          ];
    
          routes[0].children = routes[0].children.concat(actionItem);
        }
      }


      console.log(routes[0].children);

      /*
      for (let i = 0; i < routes[0].children.length; i++) {
        let child = routes[0].children[i];
        if ( child.path == '/dashboard/logging' ) {
          let newItems = []
          routes[0].children.splice(i,0,newItems);
          break;
        }
      }
      */

    }
    if (reports) {
      console.log(reports);

      let reportItems = [
        {
          path: '/dashboard/reports',
          element: <ReportDashboard />,
          auth: authRoles.admin
        }
      ];
      routes[0].children = routes[0].children.concat(reportItems);

      for (let report_idx1 = 0; report_idx1 < reports.length; report_idx1++) {
        let report = reports[report_idx1];
        //always load the route - if (allowAction(action)) 
        {

          let reportItem = [
            {
              path: '/report/'+report.report_name,
              element: <ReportDashboard report={report} />,
              auth: authRoles.admin
            },
          ];
    
          routes[0].children = routes[0].children.concat(reportItem);
        }
      }
      console.log(routes[0].children);
    }
  } catch (e) { }
  console.log(routes);
  return routes;
}

//export default routes;
export default GenRoutes;
